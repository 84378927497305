import React from 'react';
import PropTypes from 'prop-types';

import './blob.scss';

const Blob = ({ params, path, className }) => {
    let classes = 'blob';
    for (let i = 0; i < params.length; i += 1) {
        const param = params[i];
        classes += ` blob--${param}`;
    }
    classes += ` ${className}`;
    classes = classes.trim();

    // const pathNumber = path.split(';').length;
    // let keyValues = '';
    // let keySplines = '';
    // if (pathNumber > 1) {
    //     for (let i = 0; i < pathNumber; i += 1) {
    //         keyValues += i * (1 / (pathNumber - 1));
    //         if (i !== pathNumber - 1) {
    //             keyValues += '; ';
    //             keySplines += '0.620 0.195 0.465 0.780';
    //         }
    //         if (i < pathNumber - 2) {
    //             keySplines += '; ';
    //         }
    //     }
    // }
    return (
        <svg
            className={classes}
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <path fill="inherit" d={path} />
        </svg>
    );
};

Blob.propTypes = {
    params: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Blob.defaultProps = {
    params: [],
    className: '',
};

export default Blob;
