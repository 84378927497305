import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Link } from 'gatsby';
import Nuage from '@oxeva/nuage-sdk';

import { useForm } from 'react-hook-form';
import { Title, BasicButton, ErrorMessage, A } from '@nuage/ui-components';
import OxBlob from '@molecules/visual/oxevaBlob';

import MainLayout from '@organisms/main/mainLayout';
import LogoIconC from '@images/svg/logo-color.svg';

import ComplexField from '@molecules/interactive/complexField';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './signup.scss';

const SignUp = () => {
    const { _string } = lang;

    const [clObj, setClObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const [validMail, setValidMail] = useState(null);
    const [offsetPage] = useState(0);

    const timer = useRef(null);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
    });

    const onSignin = useCallback(({ mail }) => {
        if (!clObj) { return; }
        clearTimeout(timer.current);
        setLoading(true);
        const invitation = new Nuage.Invitation({
            email: mail,
            coupon: process.env.COUPON_DEFAULT,
        });
        clObj.cl.persist(invitation).onReady((error) => {
            if (error) {
                switch (error.status) {
                case 429: {
                    setErrorType('spam');
                    break;
                }
                case 422: {
                    setErrorType('forbidden');
                    break;
                }
                default: {
                    setErrorType('connect');
                    break;
                }
                }
                timer.current = setTimeout(() => {
                    setErrorType(null);
                    setLoading(false);
                }, 3000);
            } else {
                dispatchGtagEvent('submit', {
                    event_category: 'form',
                    event_label: 'compte',
                });
                setValidMail(mail);
            }
        });
    }, [clObj]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj);
    }, []);

    return (
        <MainLayout title={_string('signup_titleTab')} socials={_string('signup_socials')} classTitle="signup" footer={false}>
            <LogoIconC className="signup__icon" />
            <div
                className="signup__block-parent"
            >
                <OxBlob
                    color="primary"
                    mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                    className="signup__blob signup__blob__main"
                    offset={offsetPage}
                />
                <div
                    className="signup__block"
                >
                    <form onSubmit={handleSubmit(onSignin)} className="signup__block__form">
                        <div className="signup__block__title">
                            <Title size="xlarge">
                                { _string('signup_form_title') }
                            </Title>
                            <p className="signup__block__title__subtitle">
                                { _string('signup_form_description') }
                            </p>
                        </div>
                        <div className="signup__block__field">
                            <ComplexField
                                name="mail"
                                id="mail"
                                rules={{
                                    required: _string('signup_error_required'),
                                    pattern: {
                                        // Official regex, do not need eslint
                                        // eslint-disable-next-line no-control-regex
                                        value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                        message: _string('signup_error_mail'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: _string('signup_error_mail', { length: 255 }),
                                    },
                                }}
                                controlForm={control}
                                placeholder={_string('onboarding_field_mail_placeholder')}
                            />
                        </div>
                        { errorType && (
                            <ErrorMessage opened>
                                {_string(`signup_error_${errorType}`)}
                            </ErrorMessage>
                        )}
                        <BasicButton
                            onClick={handleSubmit(onSignin)}
                            disabled={Object.keys(errors).length !== 0 || loading}
                        >
                            {_string('signup_next_btn')}
                        </BasicButton>
                    </form>
                    <p className="signup__notice">
                        {`${_string('main_mail_notice')} `}
                        <A
                            className="signup__notice__link"
                            color="tertiary"
                            linkProps={{ href: '/mentions-legales#Politique_de_donnees_personnelles' }}
                        >
                            {_string('main_mail_notice_link')}
                        </A>
                        .
                    </p>
                    <div className="signup__block__signin">
                        <p>{_string('signup_signin_text')}</p>
                        <A TypeLink={Link} linkProps={{ to: '/signin' }}>
                            {_string('signup_signin_cta')}
                        </A>
                    </div>
                    <div className={`signup__confirm${validMail ? ' signup__confirm--visible' : ''}`}>
                        <p className="signup__confirm__title">
                            {_string('signup_confirm_title')}
                        </p>
                        <p className="signup__confirm__text">
                            {_string('signup_confirm_text', { mail: validMail })}
                        </p>
                        <A className="signup__confirm__link" TypeLink={Link} linkProps={{ to: '/' }}>
                            {_string('signup_confirm_cta')}
                        </A>
                    </div>
                </div>
                <OxBlob
                    color="primary-hover"
                    mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                    className="signup__blob signup__blob__secondary"
                    offset={offsetPage}
                />
            </div>
        </MainLayout>
    );
};

export default SignUp;
