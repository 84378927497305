// import React, { useState, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Blob from '@atoms/embed/blob';

import './oxevaBlob.scss';

/**
 * THE OXEVA blob ! Each blob shuffle the ordre of the pats to bet his very own behaviour
 */
const OxBlob = ({
    color,
    format,
    className,
    paths,
    mainShape,
    offset,
}) => {
    const [hidden] = useState(false);
    const [isReady, setReady] = useState(false);

    const params = [color, format];

    useEffect(() => {
        if (offset === null && !isReady) { return; }
        setReady(true);
    }, [offset, isReady]);

    return isReady && (
        <div
            className={`oxeva-blob-website${className ? ` ${className}` : ''}${isReady ? ' oxeva-blob-website--ready' : ''}`}
        >
            <Blob
                params={params}
                hidden={hidden}
                path={mainShape || paths[Math.floor(Math.random() * paths.length)]}
            />
        </div>
    );
};

OxBlob.propTypes = {
    /**
     * The type of the blob
     */
    color: PropTypes.oneOf([
        'primary',
        'primary-hover',
        'secondary',
        'white',
        'tertiary',
        'shadow',
    ]),
    /**
     * Have it a border, fill or only text
     */
    format: PropTypes.oneOf(['dashed', 'filled']),
    /**
        If you want to define specific path for the blob
    */
    paths: PropTypes.arrayOf(PropTypes.string),
    /**
        If you have a main shape to define
    */
    mainShape: PropTypes.string,
    /**
        To adding more classes...
    */
    className: PropTypes.string,
    offset: PropTypes.number,
};

OxBlob.defaultProps = {
    color: 'primary',
    format: 'filled',
    // noShuffle: false,
    className: '',
    mainShape: '',
    paths: [
        'M158.14,50.3c17.36,17.56,23.16,44.91,19.42,65.6-1.8,10-8.08,18-16.89,26.19-9.41,8.78-20.34,15.18-33.22,21.4-12.43,6-25.21,11.56-37.17,13.51S68,178.13,59.59,173.06c-16.94-10.13-8.33-39.28-15.2-67.29S14.83,53,25.84,36.93,69.53,16.63,93.6,20.52,140.79,32.69,158.14,50.3Z',
        'M166.86,45c17.36,17.56,16.31,50.72,12.57,71.41-1.8,10-4.88,22.89-13.69,31.08-9.41,8.78-22.88,15.17-35.76,21.39-12.43,6-27.35,8.28-39.31,10.23s-23,1.14-31.44-3.93C42.29,165,40.56,137.44,33.69,109.43S19.86,56,30.87,39.88,87.33,16.11,111.4,20,149.51,27.34,166.86,45Z',
        'M129,62.59C146.4,80.15,188.72,102,185,122.66c-1.8,10-5.08,20-13.89,28.16-9.41,8.78-31.05,9.54-43.93,15.76-12.43,6-24.53,10.53-36.49,12.48s-21.14-2.15-29.58-7.22c-16.94-10.13-18.66-35.06-25.53-63.07s-11-53,0-69.08,30.13-22.09,54.2-18.2S111.69,45,129,62.59Z',
        'M165.83,48.7c17.36,17.56,17.34,47,13.6,67.66-1.8,10-8,19.17-16.81,27.36-9.41,8.78-22.58,9.51-35.46,15.73-12.43,6-24.53,17.66-36.49,19.61s-23,1.14-31.44-3.93c-16.94-10.13-10-40.6-16.91-68.61S21.93,52.76,32.94,36.69,90,16.07,114,20,148.48,31.09,165.83,48.7Z',
    ],
    offset: null,
};

export default OxBlob;
